import { Button, Stack } from "@mui/material";
import { useMemo } from "react";
import { MdChevronLeft } from "react-icons/md";
import TakePicture from "./take-picture";
import UnitSelect from "./unit-select";
// import WebCamera from "./web-camera";
import PreRegisterForm from "./pre-register-form";
import { useSteps } from "../contexts/steps-context";
import SuccessFormSubmit from "./success-form-submit";
// import { VideoProvider } from "../contexts/video-context";
import WebCamera from "../components/WebcamComponent";
import { useCondominio } from "../contexts/condominio-context";

export default function PreRegisterFormContainer() {
  const { step, prevStep } = useSteps();
  const { flg_link_cadastro_off } = useCondominio();

  const component = useMemo(() => {
    switch (step) {
      case 0:
        return <UnitSelect />;
      case 1:
        return <TakePicture />;
      case 2:
        return <WebCamera />;
      case 3:
        return <PreRegisterForm />;
      case 4:
        return <SuccessFormSubmit />;
      default:
        return <></>;
    }
  }, [step]);

  return (
    <Stack alignItems="center" spacing={2}>
      {step !== 4 ? (
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          spacing={1}
        >
          {!flg_link_cadastro_off && (
            <Button
              variant="outlined"
              startIcon={<MdChevronLeft />}
              onClick={prevStep}
              disabled={step === 0}
            >
              Voltar
            </Button>
          )}
        </Stack>
      ) : null}

      <Stack position={"relative"} minWidth={320} width={"100%"} spacing={2}>
        {component}
      </Stack>
    </Stack>
  );
}
